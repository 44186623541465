<template>
    <main id="User-favorites-page-container" class="showroom-background-pattern">
       <div id="baniere" >
           
           <img src="../../../assets/pages-assets/category-page-assets/objects/mes favoris.png" alt="">
           <div class="descriptionEtTitre"><h2>Mes Favoris</h2>
           <p>Regardez ici les articles que vous avez remarqués sur le site.</p></div>
           
       </div>
       <div id="divider"></div>
       <AdGrid ref="adGrid" :templateMode="templateMode"/>
        
    </main>
</template>

<script>

import FavoritesService from '../../../services/FavoritesService.js'
import AnnoncesService from '../../../services/AnnoncesService.js'
import AdGrid from '../../shared-component/ad-grid/ad-grid.vue'

export default {
    name: "UserFavoritesPage",
    components: {
        AdGrid
    },
     props: ['templateMode'],
    data() {
        return {
        }
    },
    created(){
        
        this.$emit('headerState', 3);
        this.itemsList= [];
        FavoritesService.getFavorites().then((annoncesIdArray)=>{
            AnnoncesService.getMultipleAnnonces(annoncesIdArray).then((annoncesArray)=>{
                this.$refs.adGrid.setAnnonces(annoncesArray);
            })
        });
    },
    mounted() {
        
       
    },

    methods: {
        getImageStyle(){
            const header = document.getElementById("Header-component-container");
            return "width:"+header.width+"px;";

        }
       
     

    }
    
};
</script>

<style lang="scss">
    @import "my-account-favorites-page.scss";
</style>